* {
  box-sizing: border-box;
  font-size: 14px;
  font-family: sans-serif;
}

:root {
  --color-1: #242526;
  --color-2: white;
  --color-3: #0084ff;
  --color-4: grey;
  --color-5: #eb4043;
  --color-6: #333;
}

section {
  max-width: 300px;
  margin: 0 auto;
  margin-top: 40px;
  box-shadow: 1px 2px 10px var(--color-4);
  padding: 10px;
  border-radius: 10px;
}

section h3 {
  text-align: center;
  font-size: 20px;
  color: var(--color-4);
}

/* Register */
section .form {
  margin-top: 3px;
  padding: 0px 30px 30px;
  z-index: 99;
}

.input_container {
  margin-top: 2px;
}

.input_container input {
  width: 100%;
  padding: 10px;
  outline: none;
  margin-bottom: 5px;
  border: 1px solid var(--color-6);
  border-radius: 10px;
}

.btn_container {
  margin: 10px 0px;
  text-align: center;
}

.btn {
  padding: 10px;
  border-radius: 10px;
  outline: none;
  border: 1px solid var(--color-4);
  background: var(--color-1);
  color: var(--color-2);
  cursor: pointer;
  /* transition: 0.1s ease-in-out all; */
  font-size: 16px;
}

.btn:hover {
  transform: scale(1.05);
}

.error {
  text-align: center;
  color: var(--color-5);
}

/* オープニング動画 */

.VideoBg {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.topAccountLogin {
  mix-blend-mode: difference;
}

.wf-roundedmplus1c {
  font-family: "M PLUS Rounded 1c";
}

.testcss {
  width: 120px;
  height: 90px;
  object-fit: cover;
}

/* infowindow */
.gm-style-iw + div {
  display: none;
}

.gm-ui-hover-effect {
  display: none !important;
}

/* スマホの入力フォームのズームを防ぐ */
input {
  font-size: 16px;
}

.balloon-left {
  position: relative;
  display: inline-block;
  min-width: 120px;
  max-width: 100%;
  background: #e0edff;
}
.balloon-left:before {
  content: "";
  position: absolute;
  top: 50%;
  left: -20px;
  margin-top: -12px;
  border: 12px solid transparent;
  border-right: 12px solid #e0edff;
  z-index: 2;
}
.balloon-left p {
  margin: 0;
  padding: 0;
}

.balloon-left-answer {
  position: relative;
  display: inline-block;
  min-width: 120px;
  max-width: 100%;
  background: rgb(187 247 208);
}
.balloon-left-answer:before {
  content: "";
  position: absolute;
  top: 50%;
  left: -20px;
  margin-top: -12px;
  border: 12px solid transparent;
  border-right: 12px solid rgb(187 247 208);
  z-index: 2;
}
.balloon-left-answer p {
  margin: 0;
  padding: 0;
}

/* .str-chat__channel-list {
  width: 30%;
}
.str-chat__channel {
  width: 100%;
}
.str-chat__thread {
  width: 45%;
} */

.reactPlayer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.playerWrapper {
  position: relative;
  padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
}

.reactPlayer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
