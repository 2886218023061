.lexicalEditor-h1 {
    font-size:48px;
    font-weight: bold;
}
.lexicalEditor-h2 {
    font-size:40px;
    font-weight: bold;
}
.lexicalEditor-h3 {
    font-size:32px;
}
