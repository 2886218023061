.str-chat__messaging-input {
  background: #ffffff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  padding: 8px;
  position: relative;
}

.str-chat.dark .str-chat__messaging-input {
  background: #2e3033;
  border-top: 1px solid rgba(0, 0, 0, 0.16);
}

.messaging-input__container {
  display: flex;
  align-items: center;
}

.messaging-input__input-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 40px;
  height: fit-content;
  z-index: 100;
  border-radius: 20px;
  background: #ffffff;
  border: 2px solid rgba(0, 0, 0, 0.16);
  transition: border 0.2s ease-in-out;
  position: relative;
}

.str-chat.dark .messaging-input__input-wrapper {
  border: 2px solid rgba(0, 0, 0, 0.16);
  background: #323437;
}

.str-chat__messaging-input .messaging-input__input-wrapper:focus-within {
  border: 2px solid #005fff;
}

.str-chat__messaging-input > *:not(:first-of-type) {
  margin-left: 8px;
  position: absolute;
  bottom: 100%;
  left: 0;
}

.str-chat__textarea {
  display: flex;
  align-items: center;
}

.str-chat__textarea textarea {
  background: #ffffff;
  font-size: 14px;
  line-height: 16px;
  min-height: 0;
  transition: box-shadow 0.2s ease-in-out;
  color: rgba(0, 0, 0, 0.9);
  border: none !important;
  outline: none !important;
  border-radius: 20px;
  padding: 11px;
}

.str-chat.dark .str-chat__messaging-input .str-chat__textarea textarea {
  background: #323437;
  border: 1px solid rgba(0, 0, 0, 0.16);
  color: rgba(255, 255, 255, 0.9);
}

.str-chat__textarea textarea:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  width: 100%;
}

.messaging-input__button {
  opacity: 0.5;
  cursor: pointer;
  padding: 0 4px;
  transition: opacity 0.2s ease-in-out;
}

.messaging-input__button svg path {
  transition: fill 0.2s ease-in-out;
  fill: black;
}

.messaging-input__button:hover {
  opacity: 1;
}

.messaging-input__button:hover svg path {
  fill: #005fff !important;
}

.str-chat__input--emojipicker {
  z-index: 100;
  /* left: 36px; */
  right: 35px;
  bottom: 100px;
}

.str-chat__thread .str-chat__input--emojipicker {
  position: absoluted;
  /* top: 25% !important; */
  /* right: 330px; */
  left: 0;
  bottom: 100%;
}

.str-chat__messaging-input .emoji-mart-bar,
.str-chat__messaging-input .emoji-mart-search input {
  /* border-color: rgba(0, 0, 0, 0.36); */
  border: none;
}

.str-chat.dark .str-chat__messaging-input .messaging-input__button svg path {
  fill: white;
}

.giphy-icon__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 24px;
  width: 63px;
  background: #005fff;
  border-radius: 12px;
  margin-left: 8px;
}

.giphy-icon__text {
  font-family: Helvetica Neue, sans-serif;
  font-weight: bold;
  font-size: 11px;
  line-height: 8px;
  color: #ffffff;
}

/* div.rfu-dropzone__inner {
  display: none;
} */

div.rfu-dropzone {
  width: 100%;
}

div.rfu-dropzone:focus {
  outline: none;
}

.rfu-image-previewer {
  flex: none;
  margin-left: 12px;
}

.rfu-image-previewer__image {
  margin-bottom: 0;
}

div.rta__autocomplete.str-chat__emojisearch {
  z-index: 10;
  position: absolute;
  width: 30%;
  background: #fafafa;
  margin: 4px 10px;
  border-radius: 16px !important;
}

.str-chat__thread div.rta__autocomplete.str-chat__emojisearch {
  width: 100%;
  left: -10px;
}

.str-chat__user-item {
  background: #ffffff;
  color: #000000;
}

.str-chat.dark .str-chat__user-item {
  background: rgba(46, 48, 51, 0.98);
  color: #ffffff;
}

.str-chat__user-item:hover {
  background: #005fff !important;
  color: #ffffff;
  cursor: pointer;
}

.rta__entity--selected {
  background: #005fff;
}

.str-chat__slash-command:hover {
  background: #005fff;
  cursor: pointer;
}

.rta__list-header {
  font-family: Helvetica Neue, sans-serif;
  font-size: 14px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.9);
  mix-blend-mode: normal;
}

.str-chat__textarea {
  width: 100%;
}

.str-chat__textarea textarea {
  width: 100%;
  overflow: hidden;
  resize: none;
}


/* adding original */

.str-chat .rfu-dropzone .rfu-dropzone__notifier {
  position: absolute;
  height: 100%;
  width: 100%;
  padding: var(--xxs-p);
  z-index: 1001;
  display: none;
}

.rfu-dropzone__inner {
  width: 100%;
  height: 100%;
  padding: 0 var(--lg-p);
  border: 1px dashed rgba(0,0,0,0);
  box-sizing: border-box;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: var(--white);
  font-weight: 800;
  font-size: var(--sm-font);
}



.rfu-image-previewer__image, .rfu-image-previewer-angular-host__image {
  width: 60px !important;
  height: 60px !important;
}
.rfu-image-previewer__image {
  width: 100px;
  height: 100px;
  position: relative;
  margin-right: 8px;
  margin-bottom: 8px;
  align-items: center;
  justify-content: center;
}
.rfu-image-previewer, .rfu-image-previewer__image {
  display: flex;
}

.rfu-image-previewer__image .rfu-thumbnail__wrapper, .rfu-image-previewer-angular-host__image .rfu-thumbnail__wrapper {
  width: 60px !important;
  height: 60px !important;
  border-radius: var(--border-radius-md);
}

.rfu-image-previewer__image .rfu-thumbnail__wrapper {
  position: absolute;
}
.rfu-thumbnail__wrapper {
  width: 100px;
  height: 100px;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}

.rfu-image-previewer__image .rfu-thumbnail__wrapper .rfu-thumbnail__overlay, .rfu-image-previewer__image .rfu-thumbnail__wrapper .rfu-icon-button, .rfu-image-previewer-angular-host__image .rfu-thumbnail__wrapper .rfu-thumbnail__overlay, .rfu-image-previewer-angular-host__image .rfu-thumbnail__wrapper .rfu-icon-button {
  padding: 0;
}

.rfu-image-previewer__image--loaded .rfu-thumbnail__overlay {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0, transparent);
}
.rfu-thumbnail__overlay {
  position: absolute;
  background-color: rgba(0,0,0,.4);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 5px;
}

.rfu-icon-button {
  cursor: pointer;
  position: relative;
  padding: 4px;
  align-items: center;
  justify-content: center;
  border: none;
  background: rgba(0,0,0,0);
}

.rfu-icon-button svg {
  position: relative;
  z-index: 50;
  fill: #a0b2b8;
}

/* 「ファイル選択されていません」を消去 */
.rfu-image-input{
  display: none;
}

.rfu-file-input{
  display: none;

}


/* イメージのプレースホルダー */
.rfu-image-upload-button {
  cursor: pointer;
}
.rfu-image-upload-button label {
  cursor: pointer;
}
.rfu-image-upload-button .rfu-image-input {
  width: 0;
  height: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.rfu-image-previewer .rfu-thumbnail-placeholder {
  width: 60px;
  height: 60px;
  border-radius: var(--border-radius-md);
}
.rfu-thumbnail-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border: 1px dashed #bfbfbf;
  border-radius: 4px;
  cursor: pointer;
}
.rfu-image-upload-button svg {
  fill: #a0b2b8;
}

/* 「＋」アイコンの部分 */
/* .str-chat__message-input .str-chat__message-input-inner .str-chat__file-input-container {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: calc(var(--str-chat__spacing-px)*45);
  justify-content: center;
  width: calc(var(--str-chat__spacing-px)*45);
}

.str-chat__message-input .str-chat__message-input-inner .str-chat__file-input-container .str-chat__file-input-label {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
} */


/* adding original -end- */


@media screen and (max-width: 640px) {
  div.rta__autocomplete.str-chat__emojisearch {
    width: unset;
  }

  .str-chat__textarea textarea {
    font-size: 16px;
  }
}
